import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MainLayout, EmptyLayout, SimplesNacionalLayout } from "@layouts";
import LoggedInRoute from "./LoggedInRoute";
// import Route from './Route';
import RouteWithoutGov from "./RouteWithoutLoginGov";

const newMain = lazy(() => import("@pages/newMain/servicos"));
const OpenMei = lazy(() => import("@pages/newMain/pages/open-mei"));
const WhatIsMei = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/significadomei")
);
const AlreadyMei = lazy(() => import("@pages/newMain/pages/already-mei"));
const BeforeBeMei = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/saberantes/index")
);
const MeiRights = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/meirigths")
);
const StartWell = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/comecarbem")
);
const SecurityTips = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/segurancaBombeiros")
);
const SecuritySurveillance = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/segurancaSanitaria")
);
const Sustainability = lazy(
  () => import("@pages/newMain/pages/open-mei/pages/sustentabilidade")
);
const AtualizarMei = lazy(
  () => import("@pages/newMain/pages/already-mei/pages/updateMei")
);
const FormUpdateMei = lazy(
  () =>
    import(
      "@pages/newMain/pages/already-mei/pages/updateMei/components/FormUpdateMei"
    )
);

const Login = lazy(() => import("@pages/login"));
const LoginGov = lazy(() => import("@pages/common/loginGov"));
const FormalizeSe = lazy(() => import("@pages/formalizeSe"));
const FormCadastroMEI = lazy(() => import("@pages/formalizeSe/cadastroMei"));

const CertidoesEcomprovantes = lazy(
  () => import("@pages/certidoesComprovantes")
);

const CCMEI = lazy(() => import("@pages/CCMEI"));
const WhatIs = lazy(() => import("@pages/CCMEI/pages/WhatIsCCMEI"));
const AuthCCMEI = lazy(() => import("@pages/CCMEI/pages/AuthCCMEI"));
const CCMEIPdf = lazy(() => import("@pages/CCMEI/pages/Receipt/CCMEI"));

const ConsultaCNPJ = lazy(() => import("@pages/consultaCNPJcancelados"));
const ContribuicaoMensal = lazy(() => import("@pages/contribuicaoMensal"));
const ContribuicaoMensalHome = lazy(
  () => import("@pages/contribuicaoMensal/contribuicaoMensalHome")
);
const DeclaracaoAnual = lazy(() => import("@pages/declaracaoAnual"));
const DeclararAnual = lazy(() => import("@pages/declaracaoAnual/declarar"));
const PdfGerado = lazy(() => import("@pages/declaracaoAnual/pdfGenerate"));
const DeclaracaoAnualTabela = lazy(
  () => import("@pages/declaracaoAnual/declarar/tableResult")
);
const ComprovanteSituacao = lazy(
  () => import("@pages/certidoesComprovantes/comprovanteSituacao")
);
const PdfData = lazy(() => import("@pages/formalizeSe/pdfData"));
const NewGov = lazy(() => import("@pages/common/NewGov"));
const DefinePswd = lazy(() => import("@pages/common/NewGov/definePasswd"));

const Pages = () => {
  return (
    <Suspense fallback={<div>Carregando ...</div>}>
      <Switch>
        {/* 
          FORMALIZE_SE MEI CARD
          
       */}
        <LoggedInRoute
          path="/formalizacao"
          exact
          layout={MainLayout}
          component={FormalizeSe}
        />
        <LoggedInRoute
          path="/formalizacao/form-cadastro/:edit?"
          exact
          layout={MainLayout}
          component={FormCadastroMEI}
        />
        <LoggedInRoute
          path="/formalizacao/resultado"
          exact
          component={PdfData}
          layout={MainLayout}
        />

        {/* 
        ATUALIZE DADOS MEI CARD
     */}
        <LoggedInRoute
          path="/atualizar-dados"
          exact
          layout={MainLayout}
          component={AtualizarMei}
        />
        <LoggedInRoute
          path="/atualizar-dados/form-update"
          exact
          layout={MainLayout}
          component={FormUpdateMei}
        />
        <LoggedInRoute
          path="/atualizar-dados/form-update/resultado"
          exact
          component={PdfData}
          layout={MainLayout}
        />
        {/* 
        CONSULTE OS CNPJ CANCELADOS
      */}
        <LoggedInRoute
          path="/consulta-cnpj"
          exact
          component={ConsultaCNPJ}
          layout={MainLayout}
        />

        {/* PAGUE SUA CONTRIBUIÇÃO MENSAL */}
        <LoggedInRoute
          path="/contribuicao-mensal"
          exact
          component={ContribuicaoMensal}
          layout={SimplesNacionalLayout}
        />
        <LoggedInRoute
          path="/contribuicao-mensal/home"
          exact
          component={ContribuicaoMensalHome}
          layout={SimplesNacionalLayout}
        />

        {/* FAÇA SUA DECLARAÇÃO ANUAL DE FATURAMENTO */}

        <LoggedInRoute
          path="/declaracao-anual"
          exact
          component={DeclaracaoAnual}
          layout={SimplesNacionalLayout}
        />

        <LoggedInRoute
          path="/declaracao-anual/declarar"
          exact
          component={DeclararAnual}
          layout={SimplesNacionalLayout}
        />
        <LoggedInRoute
          path="/declaracao-anual/declarar/coleta-dados"
          exact
          component={DeclaracaoAnualTabela}
          layout={SimplesNacionalLayout}
        />
        <LoggedInRoute
          path="/declaracao-anual/declarar/coleta-dados/resultado"
          exact
          component={PdfGerado}
          layout={MainLayout}
        />

        {/* PÁGINAS COMMON DA APLICAÇÃO E QUE NAO PRECISAM LOGAR NO GOV SYSTEM */}

        <RouteWithoutGov
          path="/servicos"
          exact
          component={newMain}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/o-que-e-ser-mei"
          exact
          component={WhatIsMei}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/o-que-saber-antes-de-ser-mei"
          exact
          component={BeforeBeMei}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/comecar-bem"
          exact
          component={StartWell}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/seguranca-bombeiro"
          exact
          component={SecurityTips}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/seguranca-vigilancia"
          exact
          component={SecuritySurveillance}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/dicas-sustentabilidade"
          exact
          component={Sustainability}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/direitos"
          exact
          component={MeiRights}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/abrir-mei"
          exact
          component={OpenMei}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/ja-sou-mei"
          exact
          component={AlreadyMei}
          layout={MainLayout}
        />
        {/* Login govAccount necessário para gerar CCMEI */}
        <LoggedInRoute
          path="/ja-sou-mei/ccmei"
          exact
          component={CCMEI}
          layout={MainLayout}
        />

        <LoggedInRoute
          path="/ja-sou-mei/ccmei/o-que-e-ccmei"
          exact
          component={WhatIs}
          layout={MainLayout}
        />

        <LoggedInRoute
          path="/ja-sou-mei/ccmei/auth"
          exact
          component={AuthCCMEI}
          layout={MainLayout}
        />

        <LoggedInRoute
          path="/ja-sou-mei/ccmei/pdf-ccmei"
          exact
          layout={MainLayout}
          component={CCMEIPdf}
        />

        <RouteWithoutGov
          path="/login-gov"
          exact
          component={LoginGov}
          layout={EmptyLayout}
        />

        <RouteWithoutGov
          path="/cadastro-gov"
          exact
          component={NewGov}
          layout={MainLayout}
        />

        <RouteWithoutGov
          path="/cadastro-gov/define-senha"
          exact
          component={DefinePswd}
          layout={MainLayout}
        />

        {/* 
        CERTIDÕES E COMPROVANTES
      */}
        <Route path="/comprovantes">
          <ComprovanteSituacao />
        </Route>

        <Route path="/cnpjreva/:id?">
          <CertidoesEcomprovantes />
        </Route>

        {/* ROTAS QUE NÃO PRECISAM DE AUTENTICAÇÃO */}
        <Route path="/login/:id" exact>
          <Login />
        </Route>

        <Route exact render={() => <Redirect to="/login/:id" />} />
      </Switch>
    </Suspense>
  );
};
export default Pages;
