import { Types as formMeiTypes } from "@store/ducks/form_mei";
import { Address, FictionalCompany, UserGovAccount } from "@models";
import { put, call, select } from "redux-saga/effects";
import { BaseAction, IFictionalCompany } from "types";
import { ApiResponse } from "apisauce";
import { RootReducerType, sucessCallback } from "@store/modules/rootReducer";
import history from "@history";
import { toast } from "react-toastify";
import { FictionalCompanyService, VoterService } from "../../services/api";

export function* saveWaysOfActions({ payload }: BaseAction) {
  const { data } = payload;
  if (data && data !== null) {
    try {
      yield put({
        type: formMeiTypes.SUCCESS_UPDATE_WAYS_OF_ACTIONS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
      // throw new Error('Error saving ways of actions redux saga');
    }
  }
  // error notify here
}

export function* getByAccessCode({ payload }: BaseAction) {
  const { data } = payload;
  if (data && data !== null) {
    try {
      const { id } = yield select(
        (state: RootReducerType) => state.session.user
      );

      const response: ApiResponse<FictionalCompany> = yield call(
        FictionalCompanyService.getByAccessCode,
        data,
        id
      );
      const navigate = function navigatePage() {
        toast.success("Autenticado com sucesso");
        history.push("/atualizar-dados/form-update", {
          status: "COMPLETED",
          message: "navegando para formulário de atualização cadastral",
        });
      };

      yield sucessCallback(
        response,
        response.data,
        formMeiTypes.SUCCESS_FICTIONAL_COMPANY,
        navigate
      );
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        yield put({
          type: formMeiTypes.ERROR_FICTIONAL_COMPANY,
          payload: error.message,
        });
      }
      // Não há necessidade de lidar com outros tipos de erro aqui.
    }
  } else {
    toast.error("Insira o código");
  }
}

export function* saveFictionalActivities({ payload }: BaseAction) {
  const { data } = payload;
  if (data && data !== null) {
    try {
      yield put({
        type: formMeiTypes.SUCCESS_UPDATE_FICTIONAL_ACTIVITY,
        payload: data,
      });
    } catch (error) {
      console.error(error);
      // throw new Error('Error in saving fictional activities redux saga');
    }
  }
}

function copyAddress(address: Address, destination: IFictionalCompany) {
  destination.addresses.splice(1, 1, { type: "RESIDENCIAL", ...address });
  // destination.addresses[1].type = 'RESIDENCIAL';
}
export function* registerFictionalCompany({ payload }: BaseAction) {
  const { data } = payload;

  const { isEqualAddresses } = data;
  if (isEqualAddresses) {
    copyAddress(data.addresses[0], data);
    data.addresses[1].type = "RESIDENCIAL";
  }

  try {
    const { id } = yield select(
      (state: RootReducerType) => state.session_gov.userGov
    );

    const response: ApiResponse<FictionalCompany> = yield call(
      FictionalCompanyService.registerCompany,
      data,
      id
    );
    const navigate = function navigatePage() {
      toast.success("Empresa cadastrada com sucesso ");
      history.push("/formalizacao/resultado", {
        status: "COMPLETED",
      });
    };

    yield sucessCallback(
      response,
      response.data,
      formMeiTypes.SUCCESS_FICTIONAL_COMPANY,
      navigate
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put({
        type: formMeiTypes.ERROR_FICTIONAL_COMPANY,
        payload: error.message,
      });
      // yield put({
      //   type: formMeiTypes.ERROR_FICTIONAL_COMPANY,
      //   payload: error.message,
      // });
      toast.error(error.message);
    }
  }
}

export function* getByVoter({ payload }: BaseAction) {
  const { voterRegistration } = payload;

  try {
    const { id } = yield select(
      (state: RootReducerType) => state.session_gov.userGov
    );

    const response: ApiResponse<UserGovAccount> = yield call(
      VoterService.getVoter,
      { voterRegistration, idGovAccount: id }
    );

    const navigate = function navigatePage() {
      toast.success("Autenticado com sucesso");
      history.push("/formalizacao/form-cadastro", {
        status: "COMPLETED",
        message: "navegando para formulário de cadastro",
      });
    };

    yield sucessCallback(
      response,
      response.data,
      formMeiTypes.GET_VOTER_SUCCESS,
      navigate
    );
  } catch (err) {
    const errorMessage = (err as Error).message;
    toast.error(errorMessage);
    yield put({ type: formMeiTypes.GET_VOTER_ERROR, payload: errorMessage });
  }
}

export function* updateFictionalCompany({ payload }: BaseAction) {
  const { data } = payload;

  const { isEqualAddresses } = data;
  if (isEqualAddresses) {
    copyAddress(data.addresses[0], data);
    data.addresses[1].type = "RESIDENCIAL";
  }

  try {
    const { id } = yield select(
      (state: RootReducerType) => state.session_gov.userGov
    );
    const { cnpj } = yield select(
      (state: RootReducerType) => state.form_mei.fictionalCompany
    );

    const response: ApiResponse<FictionalCompany> = yield call(
      FictionalCompanyService.updateFictionalCompany,
      data,
      cnpj
    );
    const navigate = function navigatePage() {
      toast.success("Empresa Atualizada com sucesso ");
      history.push("/atualizar-dados/form-update/resultado", {
        status: "COMPLETED",
        message: "finalizada alteração cadastral de empresa",
      });
    };
    yield sucessCallback(
      response,
      response.data,
      formMeiTypes.SUCCESS_FICTIONAL_COMPANY,
      navigate
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put({
        type: formMeiTypes.ERROR_FICTIONAL_COMPANY,
        payload: error.message,
      });

      toast.error(error.message);
    }
  }
}

export function* getByCnpj({ payload }: BaseAction) {
  const { cnpj, id, route } = payload.data;

  try {
    let userId = yield select(
      (state: RootReducerType) => state.session.user?.id
    );

    if (!userId) {
      if (!id) {
        toast.error("O ID do usuário é obrigatório.");
        return;
      }
      userId = id;
    }

    const response: ApiResponse<FictionalCompany> = yield call(
      FictionalCompanyService.getByCNPJ,
      cnpj,
      userId
    );

    if (!response || !response.data) {
      throw new Error("Falha ao buscar dados da empresa.");
    }

    const navigate = () => {
      history.push(route, {
        status: "COMPLETED",
        message: "Navegando para formulário de cadastro",
      });
    };

    yield sucessCallback(
      response,
      response.data,
      formMeiTypes.SUCCESS_FICTIONAL_COMPANY,
      navigate
    );
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message);
      yield put({
        type: formMeiTypes.ERROR_FICTIONAL_COMPANY,
        payload: err.message,
      });
    }
  }
}
